import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import reachGoalYM from "@/Helpers/ReachGoalYM";
import reachGoalGA from "@/Helpers/ReachGoalGA";
import Svg from "@/Pages/All/Svg";
import withStore from "@/hocs/withStore";
import MobileSubMenu from "@/Pages/Header/MobileSubMenu";
import CountryList from "@/Pages/Header/CountryList";
import LinkLoad from "@/Layouts/LinkLoad";

const MobileMenu = (props) => {
    const {stores = {}, config = {}, city} = props;
    const {mobileMenuStore = {}, selectCityStore = {}, subMenuStore = {}, modalStore = {}} = stores;
    const {countryList} = config;

    const countryListShift = Object.assign({}, countryList[0]);

    const MobileMenuStart = () => {
        if (!subMenuStore.subMenuFlag) {
            return <>
                <div className={`nav__item`}>
                    <LinkLoad href={`/stocks`} onClick={() => mobileMenuStore.closeMobileMenu()}>Акции</LinkLoad>
                </div>
                <div className={`nav__item`}
                     onClick={() => {
                         subMenuStore.changeMobileSubMenu();
                         mobileMenuStore.closeMobileMenu()
                     }}>
                    <p>Каталог</p>
                </div>
                {/*<div className={`nav__item`}>*/}
                {/*    <LinkLoad href={`/wheel-of-fortune`} onClick={() => mobileMenuStore.closeMobileMenu()}>*/}
                {/*        Розыгрыш*/}
                {/*    </LinkLoad>*/}
                {/*</div>*/}
                <div className={`nav__item`}>
                    <LinkLoad href={`/buket-podarkov`} onClick={() => mobileMenuStore.closeMobileMenu()}>
                        Розыгрыш
                    </LinkLoad>
                </div>
                <div className={`nav__item`}>
                    <LinkLoad href={`/wedding`} onClick={() => mobileMenuStore.closeMobileMenu()}>
                        Свадьба
                    </LinkLoad>
                </div>
                {/*<div className={`nav__item`}>*/}
                {/*    <LinkLoad href={`/advent-calendar`} onClick={() => mobileMenuStore.closeMobileMenu()}>*/}
                {/*        Адвент-календарь*/}
                {/*    </LinkLoad>*/}
                {/*</div>*/}
                {/*<div className={`nav__item nav__item_navigation`}>*/}
                {/*    <LinkLoad href={`/collections/platina-soul`} onClick={() => mobileMenuStore.closeMobileMenu()}>*/}
                {/*        Соул-браслеты*/}
                {/*    </LinkLoad>*/}
                {/*    <div className={`icon`} onClick={() => subMenuStore.changeSubMenu('soulSubMenu')}>*/}
                {/*        <Svg id={`arrows-right`} className={`MuiSvgIcon-root`} />*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className={`nav__item`}>*/}
                {/*    <LinkLoad href={`/collections`} onClick={() => mobileMenuStore.closeMobileMenu()}>*/}
                {/*        Коллекции*/}
                {/*    </LinkLoad>*/}
                {/*</div>*/}
                <div className={`nav__item`}>
                    <LinkLoad href={`/gift-certificate`} onClick={() => mobileMenuStore.closeMobileMenu()}>
                        Подарочные сертификаты
                    </LinkLoad>
                </div>
                {/*<div className={`nav__item`}>*/}
                {/*    <LinkLoad href={`/platina-benefits`} onClick={() => mobileMenuStore.closeMobileMenu()}>*/}
                {/*        Бонусная программа*/}
                {/*    </LinkLoad>*/}
                {/*</div>*/}
                {/*<div className={`nav__item`}>*/}
                {/*    <LinkLoad href={`/sber-credit-terms`} onClick={() => mobileMenuStore.closeMobileMenu()}>*/}
                {/*        Условия кредитования и рассрочки*/}
                {/*    </LinkLoad>*/}
                {/*</div>*/}
                {/*<div className={`nav__item`}>*/}
                {/*    <LinkLoad href={`/dolyame`} onClick={() => mobileMenuStore.closeMobileMenu()}>*/}
                {/*        Покупка долями*/}
                {/*    </LinkLoad>*/}
                {/*</div>*/}
                {/*<div className={`nav__item`}>*/}
                {/*    <LinkLoad href={`/split`} onClick={() => mobileMenuStore.closeMobileMenu()}>*/}
                {/*        Покупка в сплит*/}
                {/*    </LinkLoad>*/}
                {/*</div>*/}
                <div className={`nav__item`}>
                    <LinkLoad href={`/delivery`} onClick={() => mobileMenuStore.closeMobileMenu()}>
                        Оплата и доставка
                    </LinkLoad>
                </div>
                <div className={`nav__item`}>
                    <LinkLoad href={`/blog`} onClick={() => mobileMenuStore.closeMobileMenu()}>
                        Блог
                    </LinkLoad>
                </div>
                <div className={`nav__item`}>
                    <LinkLoad href={`/contacts`} onClick={() => mobileMenuStore.closeMobileMenu()}>
                        Контакты
                    </LinkLoad>
                </div>
                <div className={`nav__item`}>
                    <a href={`https://b2b.platina-kostroma.com/web-login`}
                       target={`_blank`}>
                        Кабинет партнера
                    </a>
                </div>
                <div className={`nav__item`} onClick={() => modalStore.openWidgetModal()}>
                    Обратная связь
                </div>
            </>
        }
    }

    return <Drawer id={`drawer`}
                   className={`mobile-menu`}
                   style={{zIndex: 9999}}
                   anchor={`left`}
                   open={mobileMenuStore.open}
                   onClose={() => mobileMenuStore.closeMobileMenu()}>
        <div className={`mobile-menu__wrapper`}>
            <div className={`mobile-menu__top`}>
                <div className={`dagger-box dagger-box__mobile-menu`} onClick={() => mobileMenuStore.closeMobileMenu()}>
                    <Svg id={`dagger-close`} className={`svg__daggerCloseMenu`} />
                </div>
            </div>
            <div className={`mobile-menu__body`}>
                {mobileMenuStore.open && (
                    <MobileMenuStart />
                )}
                {subMenuStore.subMenuFlag && (
                    <MobileSubMenu typeSubMenu={`soulSubMenu`} {...props} />
                )}
            </div>
            <div className={`mobile-menu__footer`}>
                <ClickAwayListener onClickAway={subMenuStore.handleClickAway}>
                    <div className={`country`}>
                        <div className={`country__currentBox`} onClick={() => subMenuStore.switchingCountries()}>
                            <picture className={`country__currentFlag`}>
                                <source type={`image/webp`} srcSet={countryListShift.patchWebp} />
                                <img src={countryListShift.patch}
                                     alt={`Ru`}
                                     title={`Ru`}
                                     loading={`lazy`}
                                />
                            </picture>
                            <p className={`country__currentName`}>
                                {countryListShift.name}
                            </p>
                        </div>
                        <Fade in={subMenuStore.checked}>
                            <Paper
                                elevation={4}
                                style={{
                                    position: "absolute",
                                    top: '-135px',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    width: 'auto',
                                    minWidth: '300px',
                                    backgroundColor: '#FFFFFF',
                                    padding: '16px',
                                    borderRadius: '8px',
                                    zIndex: 2
                                }}
                            >
                                <p className={`country__title`}>Выберите страну</p>
                                <CountryList countryList={countryList} />
                            </Paper>
                        </Fade>
                    </div>
                </ClickAwayListener>
                <div className={`mobile-menu__location`} onClick={() => selectCityStore.openModal()}>
                    <Svg id={`location`} className={`svg__location`} />
                    {city}
                </div>
                <div className={`mobile-menu__phones`}>
                    <a onClick={() => {
                        reachGoalYM('phone_click');
                        reachGoalGA('phone_click');
                    }}
                       href="tel:88003501595">
                        <span className="phone">8 800 350-15-95</span>
                    </a>
                    {/*<a onClick={() => {*/}
                    {/*    reachGoalYM('phone_click');*/}
                    {/*    reachGoalGA('phone_click');*/}
                    {/*}}*/}
                    {/*   href="tel:+79109522022">*/}
                    {/*    <span className={`phone`}>8 910 952-20-22</span>*/}
                    {/*</a>*/}
                </div>
            </div>
        </div>
    </Drawer>
}

export default withStore(MobileMenu);
